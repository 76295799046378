import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import heroVideo from './video-assets/hero-vid.mp4';
import learnVideo from './video-assets/learn-vid.mp4';
import applyVideo from './video-assets/apply-vid.mp4';
import investVideo from './video-assets/invest-vid.mp4';
import logo from './image-assets/logo-avic.png';
import topLarge from './image-assets/top-large.png';
import rightSmall from './image-assets/right-small.png';
import leftSmall from './image-assets/left-small.png';
import aboutBackgroundVideo from './video-assets/candle-chart-about.mp4';
import footerLogo from './image-assets/footer-logo.png';
import syaonImage from './image-assets/syaon.png';
import armanImage from './image-assets/arman.png';
import jeremyImage from './image-assets/jeremy.png';
import rikhilImage from './image-assets/rikhil.png';
import diegoImage from './image-assets/diego.png';
import anthonyImage from './image-assets/anthony.png';
import yunaImage from './image-assets/yuna.png';

const TeamSection = forwardRef((props, ref) => {
  const teamMembers = [
    { id: 1, name: "Syaon Kumar", position: "Co-President", image: syaonImage },
    { id: 2, name: "Arman Dhilon", position: "Co-President", image: armanImage },
    { id: 3, name: "Jeremy Li", position: "Vice President", image: jeremyImage },
    { id: 4, name: "Rikhil Damarla", position: "Technology Officer", image: rikhilImage },
    { id: 5, name: "Diego Laredo", position: "Secretary", image: diegoImage },
    { id: 6, name: "Anthony Chen", position: "Treasurer", image: anthonyImage },
    { id: 7, name: "Yuna Kang", position: "Outreach Officer", image: yunaImage },
  ];

  return (
    <section className="team-section" ref={ref}>
      <h2 className="team-title">Officer Team (2024-2025)</h2>
      <div className="team-grid">
        {teamMembers.map((member) => (
          <div key={member.id} className="team-member">
            <div className="team-member-picture">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="team-member-description">
              <p>{member.name}</p>
              <p>{member.position}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

function useNavbarCollapse(threshold) {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= threshold);

  useEffect(() => {
    function handleResize() {
      setCollapsed(window.innerWidth <= threshold);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [threshold]);

  return collapsed;
}


function Home() {
  const heroVideoRef = useRef(null);
  const aboutVideoRef = useRef(null);
  const teamSectionRef = useRef(null);

  useEffect(() => {
    const heroVideo = heroVideoRef.current;
    const aboutVideo = aboutVideoRef.current;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    if (window.location.hash === '#team') {
      setTimeout(() => {
        scrollToTeam({ preventDefault: () => {} });
      }, 0);
    }


    const heroObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          heroVideo.play().catch(e => console.log("Hero video play interrupted:", e));
          aboutVideo.pause();
        } else {
          heroVideo.pause();
        }
      });
    }, options);

    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          aboutVideo.play().catch(e => console.log("About video play interrupted:", e));
          heroVideo.pause();
        } else {
          aboutVideo.pause();
        }
      });
    }, options);

    heroObserver.observe(heroVideo.parentElement);
    aboutObserver.observe(aboutVideo.parentElement);  

    return () => {
      heroObserver.disconnect();
      aboutObserver.disconnect();
    };
  }, []);

  const videoRefs = [useRef(null), useRef(null), useRef(null)];
  const videos = [learnVideo, applyVideo, investVideo];
  const aboutSectionRef = useRef(null);

  const handleHover = (index) => {
    setTimeout(() => {
      if (videoRefs[index].current) {
        videoRefs[index].current.play().catch(e => console.log("Play interrupted:", e));
      }
    }, 50);
  };

  const handleMouseLeave = (index) => {
    videoRefs[index].current.pause();
    videoRefs[index].current.currentTime = 0;
  };

  const scrollToAbout = () => {
    const aboutSection = document.querySelector('.about-section');
    if (aboutSection) {
      const windowHeight = window.innerHeight;
      const aboutSectionHeight = aboutSection.offsetHeight;
      const scrollPosition = aboutSection.offsetTop - (windowHeight / 2) + (aboutSectionHeight / 2);

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
    <section className="top-section"></section>
      <section className="hero-section">
        <video ref={heroVideoRef} className="hero-video" loop muted playsInline>
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-mask-container">
          {['LEARN', 'APPLY', 'INVEST'].map((text, index) => (
            <div 
              key={text} 
              className="video-item"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className="mask-text">{text}</div>
              <div className="video-mask">
                <video ref={videoRefs[index]} loop muted>
                  <source src={videos[index]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ))}
        </div>
        <div className="hero-text" onClick={scrollToAbout}>
          <div>find out more</div>
          <div>↓</div>
        </div>
      </section>
      <section className="about-section">
        <video ref={aboutVideoRef} className="about-background-video" loop muted playsInline>
          <source src={aboutBackgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <h2 className="about-title">About AVHS Investment Club</h2>
        <div className="about-content">
          <div className="about-text">
            <p>
              The Amador Valley High School Investment Club, the first <a href="https://streetfins.com/user/avic/" target="_blank" rel="noopener noreferrer" className="streetfins">StreetFins</a> club, 
              offers an accessible and supportive environment for students to learn about investing and financial literacy. 
              We host weekly meetings featuring an introductory boot camp, workshops, guest speakers, virtual stock market 
              competitions, and more.
            </p>
            <Link to="/about" className="learn-more-btn">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
              </svg>
            </Link>
          </div>
          <div className="about-images">
            <img src={topLarge} alt="Large club image" className="about-image-large" />
            <div className="about-image-small-container">
              <img src={leftSmall} alt="Small club image 1" className="about-image-small" />
              <img src={rightSmall} alt="Small club image 2" className="about-image-small" />
            </div>
          </div>
        </div>
      </section>
      <TeamSection ref={teamSectionRef} />
      <section className="footer-section">
      <img src={footerLogo} alt="Footer Logo" className="footer-logo" />
      <p className="footer-text">© 2024 Amador Valley Investment Club</p>
    </section>
    </>
  );
}

function AboutUs() {
  return <div className="page-content"><h1>Coming Soon...</h1></div>;
}

function Events() {
  return <div className="page-content"><h1>Coming Soon...</h1></div>;
}

function Resources() {
  return <div className="page-content"><h1>Coming Soon...</h1></div>;
}

function Contact() {
  return <div className="page-content"><h1>Coming Soon...</h1></div>;
}

function App() {
  const isNavbarCollapsed = useNavbarCollapse(1160);

  return (
    <Router>
      <div className="App">
      <Navbar expand={!isNavbarCollapsed} fixed="top" className="custom-navbar">
      <Container fluid className="navbar-container">
            <Navbar.Brand as={Link} to="/" className="brand-container">
              <img src={logo} alt="Logo" height="40" className="d-inline-block align-top" />
            </Navbar.Brand>
            <div className="center-button">
              <a href="https://forms.gle/aJG2HHZcbYUhSSee8" target="_blank" rel="noopener noreferrer" className="join-us-btn">JOIN US!</a>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="nav-links-container">
                <Nav className="ms-auto nav-links">
                  <Nav.Link as={Link} to="/about">/about us</Nav.Link>
                  <Nav.Link as={Link} to="/#team" onClick={scrollToTeam}>/team</Nav.Link>
                  <Nav.Link as={Link} to="/events">/events</Nav.Link>
                  <Nav.Link as={Link} to="/resources">/resources</Nav.Link>
                  <Nav.Link as={Link} to="/contact">/contact</Nav.Link>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

function scrollToTeam(e) {
  e.preventDefault();
  const teamSection = document.querySelector('.team-section');
  const navbar = document.querySelector('.custom-navbar');
  if (teamSection && navbar) {
    const navbarHeight = navbar.offsetHeight;
    const teamSectionPosition = teamSection.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
    window.scrollTo({ top: teamSectionPosition, behavior: 'smooth' });
  } else if (window.location.pathname !== '/') {
    window.location.href = '/#team';
  }
}

export default App;